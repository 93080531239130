import { ChromeBadge } from '~/components/Icon/ChromeBadge';

const usersBadgeUrl =
    'https://img.shields.io/chrome-web-store/users/lhgpaoichdcipgelclannkcembnndfpa?label=users&style=for-the-badge';

const ratingBadgeUrl =
    'https://img.shields.io/chrome-web-store/rating/lhgpaoichdcipgelclannkcembnndfpa?style=for-the-badge';
const versionBadgeUrl =
    'https://img.shields.io/chrome-web-store/v/lhgpaoichdcipgelclannkcembnndfpa?style=for-the-badge';

export function ExtensionHero() {
    return (
        <section className="container relative mx-auto flex min-h-[80vh] flex-col items-center justify-center">
            <div className="p-4 mb-24">
                <p className="gradient-headline text-center text-xl sm:text-2xl md:text-3xl mb-4">
                    the world's first
                </p>
                <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-fuchsia-700 to-orange-400 text-center text-5xl font-bold sm:text-6xl md:text-8xl mb-6">
                    AI Copilot for dating apps
                </h1>
                <p className="gradient-headline text-center text-xl">
                    now available for Bumble and Tinder
                </p>
            </div>

            <a
                href="https://chromewebstore.google.com/detail/texting-champ-for-tinder/lhgpaoichdcipgelclannkcembnndfpa"
                target="_blank"
                rel="noopener noreferrer"
            >
                <ChromeBadge className="h-20 rounded-lg border bg-white p-2 opacity-80 dark:opacity-60 hover:opacity-100" />
            </a>

            <span className="flex-1 max-h-32 sm:max-h-64"></span>

            <div className="flex gap-2 p-2 flex-wrap justify-center">
                <img src={usersBadgeUrl} alt="Chrome Web Store users" />
                <img src={ratingBadgeUrl} alt="Chrome Web Store rating" />
                <img src={versionBadgeUrl} alt="Chrome Web Store version" />
            </div>
        </section>
    );
}
