import { PrimaryButton } from '~/components/General/PrimaryButton';
import bumbleGpt from '~/assets/screens/b-gpt.png';

export function ExtensionCTA() {
    return (
        <section className="container">
            <div className="items-center gap-8 px-4 py-8 sm:py-16 md:grid md:grid-cols-2 lg:px-6 xl:gap-16">
                <img
                    className="w-full"
                    src={bumbleGpt}
                    alt="chat-gpt inside bumble"
                />
                <div className="mt-4 md:mt-0">
                    <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-white">
                        Let's create a better online dating experience for
                        everyone.
                    </h2>
                    <p className="mb-6 font-light text-gray-400 md:text-lg">
                        Join and try the Chrome extension for dating (Copilot
                        for Tinder and Bumble) to share your voice, improve your
                        experience, and shape the future of the tool to help
                        create the best possible tool.
                    </p>

                    <a
                        href="https://chromewebstore.google.com/detail/texting-champ-for-tinder/lhgpaoichdcipgelclannkcembnndfpa"
                        target="_blank"
                    >
                        <PrimaryButton>
                            Get started
                            <svg
                                className="inline-flex h-4 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </PrimaryButton>
                    </a>
                </div>
            </div>
        </section>
    );
}
