import type { MetaFunction } from '@remix-run/cloudflare';
import { Statistics } from '~/components/Statistics';
import { ExtensionHero } from '~/components/Extension/Hero';
import { ExtensionFeatures } from '~/components/Extension/ExtensionFeatures';
import { WooshEffect } from '~/components/WooshEffect';
import { ExtensionCTA } from '~/components/Extension/CTA';

export const meta: MetaFunction = () => {
    return [
        { title: `The world's first AI Copilot for dating apps` },
        {
            name: 'description',
            content:
                'The power of GPT 4, Claude AI, Google Gemini right inside your dating app. Get more matches, better conversations, and more dates.',
        },
    ];
};

export default function Extension() {
    return (
        <>
            <WooshEffect />
            <div className="relative">
                <ExtensionHero />
                <ExtensionFeatures />
                <Statistics />
                <ExtensionCTA />
            </div>
        </>
    );
}
