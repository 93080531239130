import { NuxtCard } from '~/components/General/NuxtCard';

export function ExtensionFeatures() {
    const cards = [
        {
            title: 'ChatGPT Integration',
            description:
                "Chat directly to GPT-4 or Mistral about any of your matches (one at a time). The AI will know your messages and your match name so you don't have to repeat yourself.",
        },
        {
            title: 'Automatic saves',
            description:
                'Your chats are saved so you can always reflect on your successes and learn from your mistakes. Even if you get unmatched. Plus, you can easily share your conversations with friends or therapists for feedback.',
        },
        {
            title: 'AI Suggestions',
            description:
                'Feeling blank? Let our AI suggest the perfect opening line for you. It’s like having a personal assistant for your dating daily.',
        },
        {
            title: "'Are we dating the same guy?'",
            description:
                'Are you a girl based in a small city often finding yourself matching with the same guys as your girlfriends? Our extension will help you find out.',
            // soon: true,
        },
        {
            title: 'Translate bios',
            description:
                'Swiping in a foreign country? Our extension will translate bios for you so you can understand your matches better.',
        },
        {
            title: 'Your idea here...',
            description:
                'Extension is in early development and we are open to suggestions. What would you like to see in the little? Let us know!',
        },
    ];

    return (
        <section className="container mb-24 text-white">
            <h1 className="mb-2 max-w-3xl text-5xl font-semibold">
                Chrome extension{' '}
                <span className="bg-gradient-to-r from-green-400 to-teal-400 bg-clip-text text-transparent">
                    for your success
                </span>
            </h1>
            <p className="max-w-xl">
                Enhance your dating experience with our powerful Chrome
                Extension. Access our advanced tools directly from your browser.
            </p>
            <div className="grid grid-cols-1 gap-8 pt-16 sm:grid-cols-2 lg:grid-cols-3">
                {cards.map((card, index) => (
                    <NuxtCard key={index}>
                        <h2 className="mb-4 text-xl font-semibold">
                            {card.title}
                        </h2>
                        <p>{card.description}</p>
                        {card.soon && (
                            <span className="ms-2 rounded bg-gray-200 px-2 py-0.5 text-sm font-medium text-gray-800 dark:bg-gray-700 dark:text-gray-300">
                                coming soon
                            </span>
                        )}
                    </NuxtCard>
                ))}
            </div>
        </section>
    );
}
